import React from "react";
import ExamplesSection from "../containers/ExamplesSection";
import MainLayout from "../components/MainLayout";

export default () => {
  return (
    <MainLayout
      hasGradient
      title="Examples"
      pathname="/examples"
      description="Here’s some inspiration to help get you started with your own tests."
    >
      <ExamplesSection />
    </MainLayout>
  );
};
